import React, { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { useHistory } from 'react-router-dom';

const ProfileBanner = ({
  parsedCompletion,
  userProfile,
  setIsProfileBanner,
}) => {
  const history = useHistory();

  const [profession, setProfession] = useState(false);
  const [skills, setSkills] = useState(false);
  const [education, setEducation] = useState(false);
  const [projects, setProjects] = useState(false);
  const [experience, setExperience] = useState(false);
  const [certificates, setCertificates] = useState(false);

  useEffect(() => {
    setProfession(!userProfile?.profession);
    setSkills(!userProfile?.user_skills?.length);
    setEducation(!userProfile?.education?.length);
    setProjects(!userProfile?.projects?.length);
    setExperience(!userProfile?.experience?.length);
    setCertificates(!userProfile?.certificates?.length);
  }, [userProfile]);

  const handleFieldClick = (setFieldState) => {
    setProfession(false);
    setSkills(false);
    setEducation(false);
    setProjects(false);
    setExperience(false);
    setCertificates(false);

    setFieldState(true);

    history.push('/home/profile', {
      profession: setFieldState === setProfession,
      skills: setFieldState === setSkills,
      education: setFieldState === setEducation,
      projects: setFieldState === setProjects,
      experience: setFieldState === setExperience,
      certificates: setFieldState === setCertificates,
    });
  };

  const missingFields = [];

  const getMissingFields = () => {
    if (profession) missingFields.push(['Profession', setProfession]);
    if (skills) missingFields.push(['Skills', setSkills]);
    if (education) missingFields.push(['Education', setEducation]);
    if (projects) missingFields.push(['Projects', setProjects]);
    if (experience) missingFields.push(['Experience', setExperience]);
    if (certificates) missingFields.push(['Certificates', setCertificates]);
  };

  const userProfileMessage = () => {
    getMissingFields();

    return (
      <div>
        {missingFields.length > 0 ? (
          <p className='text-primaryBg text-xs'>
            To improve your skill score, you need to provide details about your{' '}
            {missingFields.map(([field, setState], index) => (
              <React.Fragment key={field}>
                <strong
                  className='cursor-pointer'
                  onClick={() => handleFieldClick(setState)}
                >
                  {field}
                </strong>
                {index < missingFields.length - 1 && ', '}
              </React.Fragment>
            ))}
            .
          </p>
        ) : (
          <p className='text-primaryBg text-xs'>Your profile is complete!</p>
        )}
      </div>
    );
  };

  // Only display the banner if there are missing fields and parsedCompletion is less than 87
  if (missingFields.length === 0 && parsedCompletion >= 87) {
    return null;
  }

  return (
    <div className='bg-[#CD911B] shadow-lg rounded-lg p-3 flex justify-between items-center'>
      <div>{parsedCompletion < 87 && userProfileMessage()}</div>
      <div
        className='cursor-pointer'
        onClick={() => {
          localStorage.setItem('isBanner', 'true');
          setIsProfileBanner(false);
        }}
      >
        <RxCross2 size={20} />
      </div>
    </div>
  );
};

export default ProfileBanner;
