/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

import DeleteIcon from '@material-ui/icons/Delete';

import { AppContext } from 'src/Contexts';

import { User } from '../../Api';
import addIcon from '../../Assets/images/v1/icons/add-icon.svg';
import skillsCancelIcon from '../../Assets/images/v1/icons/profile cancel icon.svg';
import skillsSelect from '../../Assets/images/v1/icons/skill-select.svg';
import { Auth as AuthConstants } from '../../Constants';
import Button from '../Button';
import Dialog from '../Dialog';
import IconButton from '../IconButton';
import Loader from '../Loader';
import PrimaryInfoInputDialog from '../PrimaryInfoInputDialog';
import ProfessionalModal from '../ProfessionalModal';
import SkillsInputDialog from '../SkillsInputDialog';

import ProfileEmptyCard from './ProfileEmptyCard';

const SkillsCard = ({ text, onDelete, className, isSelected }) => {
  return (
    <div>
      <div
        onClick={() => onDelete()}
        className={`border mx-2 my-2 cursor-pointer py-1 border-gray-300  px-2 rounded-lg flex justify-between ${className}`}
      >
        <h1 className={'mx-1'}>{text}</h1>
        <img src={isSelected ? skillsSelect : skillsCancelIcon} alt='' />
      </div>
    </div>
  );
};
const ProfileSkillsCard = ({
  user,
  getProfile,
  loader,
  placeholderText,
  placeholderImage,
}) => {
  const { state } = useLocation();
  const [softSkills, setSoftSkills] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [skillSelected, setSkillSelected] = useState([]);
  const [deleteSkillModal, setDeleteSkillModal] = useState(false);
  const [unassignedSkills, setUnassignedSkills] = useState([]);
  const [openSkill, setOpenSkill] = useState(state?.skills);
  const [modalOpen, setModalOpen] = useState(false);
  const [, setOpenEditSkill] = useState(false);
  const [profession, setProfession] = useState({});
  const [name, setName] = useState('');
  const [summary, setSummary] = useState('');
  const [image, setImage] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [openPrimaryInfo, setOpenPrimaryInfo] = useState(false);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const context = useContext(AppContext);

  const updateUser = () => context.updateUser(token);

  useEffect(() => {
    setProfession(user?.profession);
    refreshSkills(user?.user_skills);
    setName(user.name);
    setProfession(user.profession);
    setSummary(user.description);
    setCity(user.city);
    setCountry(user.country);
    setImage(user.image_url);
  }, [user]);

  const refreshSkills = (skills) => {
    const soft = [],
      hard = [],
      unassigned = [];
    skills?.forEach((skill) => {
      if (skill.type === 's') soft.push(skill);
      if (skill.type === 'h') hard.push(skill);
      if (skill.type === 'u') unassigned.push(skill);
    });
    setSoftSkills(soft);
    setHardSkills(hard);
    setUnassignedSkills(unassigned);
    updateUser();
  };

  const editSkillsHandler = async (skills) => {
    try {
      const res = await User.updateSkills(skills, token);
      refreshSkills(res?.data?.data);
      setOpenSkill(false);
      setOpenEditSkill(false);
      // homeContext.fetchUpskills({ refresh: true });
      getProfile();
    } catch (e) {
      console.error('Failed:', e);
    }
  };

  const handleSkillsUpdate = async (skills) => {
    await editSkillsHandler(skills);
  };

  const selectAllHandler = () => {
    setSkillSelected([...hardSkills, ...softSkills]);
  };

  const deleteSkillsUpdate = async () => {
    const updatedHardSkills = hardSkills.filter((skill) => {
      return !skillSelected.some(
        (selectedSkill) => selectedSkill.id === skill.id,
      );
    });
    const updatedSoftSkills = softSkills.filter((skill) => {
      return !skillSelected.some(
        (selectedSkill) => selectedSkill.id === skill.id,
      );
    });
    await editSkillsHandler([...updatedHardSkills, ...updatedSoftSkills]);
    setDeleteSkillModal(false);
    setSkillSelected([]);
  };

  const handleProfileUpdate = async (data) => {
    try {
      const res = await User.updateGeneralProfile(data, token);
      const user = res.data.data;

      setName(user.name);
      setProfession({ id: user.profession_id, name: user.profession_name });
      setImage(user.image_url ? user.image_url : image);
      setSummary(user.description);
      setOpenPrimaryInfo(false);
      setCity(user.city && user.city.length > 0 ? JSON.parse(user.city) : null);
      setCountry(
        user.country && user.country.length > 0
          ? JSON.parse(user.country)
          : null,
      );
      getProfile();
      updateUser();
    } catch (e) {
      console.error();
      'Failed:', e;
    }
  };

  const onSelectDelete = (skill) => {
    if (skillSelected.some((selectedSkill) => selectedSkill.id === skill.id)) {
      setSkillSelected(
        skillSelected.filter((selectedSkill) => selectedSkill.id !== skill.id),
      );
    } else {
      setSkillSelected([...skillSelected, skill]);
    }
  };

  return (
    <div id='skills' className='bg-white my-10 py-7 px-5'>
      <div>
        <div className='flex justify-between mb-6'>
          <h1 className='text-2xl font-semibold'>Skills</h1>
          <div className='flex items-center'>
            {hardSkills.length || softSkills.length ? (
              <p
                className='border mx-2 my-1 cursor-pointer border-lightBlue text-lightBlue  px-2 rounded-lg flex justify-between'
                onClick={selectAllHandler}
              >
                Select All
              </p>
            ) : null}

            <img
              className='cursor-pointer'
              onClick={() => {
                return setOpenSkill(true);
              }}
              src={addIcon}
              alt=''
            />
          </div>
        </div>
        {loader ? (
          <div className='flex justify-center'>
            <Loader />
            <h1 className='mt-1'>Getting your Skills</h1>
          </div>
        ) : (
          <div className='flex justify-between items-end'>
            <div className='flex flex-wrap w-10/12 '>
              {!softSkills?.length && !hardSkills?.length ? (
                <div className='ml-20 flex justify-center w-full'>
                  <ProfileEmptyCard
                    {...{ placeholderImage, placeholderText }}
                  />
                </div>
              ) : null}
              {hardSkills?.map((skill, index) => {
                const isSelected = skillSelected.some(
                  (selectedSkill) => selectedSkill.id === skill.id,
                );
                return (
                  <SkillsCard
                    key={index}
                    // onDelete={() => setOpenSkill(true)}
                    onDelete={() => onSelectDelete(skill)}
                    text={skill.name}
                    className={isSelected ? 'bg-DarkBluePurple text-white' : {}}
                    isSelected={isSelected}
                  />
                );
              })}
              {softSkills?.map((skill, index) => {
                const isSelected = skillSelected.some(
                  (selectedSkill) => selectedSkill.id === skill.id,
                );

                return (
                  <SkillsCard
                    key={index}
                    onDelete={() => onSelectDelete(skill)}
                    text={skill.name}
                    className={isSelected ? 'bg-DarkBluePurple text-white' : {}}
                    isSelected={isSelected}
                  />
                );
              })}
            </div>
            {skillSelected?.length ? (
              <IconButton onClick={() => setDeleteSkillModal(true)}>
                <DeleteIcon
                  className='-mt-1'
                  color='inherit'
                  fontSize='small'
                />
              </IconButton>
            ) : null}
          </div>
        )}
        <ProfessionalModal
          {...{ modalOpen, setModalOpen, setOpenPrimaryInfo }}
        />
        <PrimaryInfoInputDialog
          data={{ name, image, city, country, profession, summary }}
          open={openPrimaryInfo}
          onClose={() => setOpenPrimaryInfo(false)}
          onSubmitted={handleProfileUpdate}
        />
        <SkillsInputDialog
          onSubmitted={async ({ softSkills, hardSkills }) => {
            await handleSkillsUpdate([
              ...softSkills,
              ...hardSkills,
              ...Object.values(unassignedSkills),
            ]);
          }}
          open={openSkill}
          hardSkills={Object.values(hardSkills)}
          softSkills={Object.values(softSkills)}
          profession_id={profession?.id}
          onClose={() => setOpenSkill(false)}
        />
        <Dialog
          title='Delete'
          open={deleteSkillModal}
          onClose={() => setDeleteSkillModal(false)}
        >
          <div>
            <h1>
              Are you sure you want to delete{' '}
              {skillSelected.length > 1 ? 'these items' : 'this item'}.
            </h1>

            <div className='flex justify-end'>
              <div className='mx-3'>
                <Button
                  onClick={() => setDeleteSkillModal(false)}
                  title='Cancel'
                />
              </div>
              <Button onClick={deleteSkillsUpdate} title='Delete' />
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ProfileSkillsCard;
