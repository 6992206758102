/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import certificatesPlaceholder from 'src/Assets/images/profile/certificates-placeholder.svg';
import educationPlaceholder from 'src/Assets/images/profile/education-placeholder.svg';
import experiencePlaceholder from 'src/Assets/images/profile/experience.svg';
import interestsPlaceholder from 'src/Assets/images/profile/interests-placeholder.svg';
import projectsPlaceholder from 'src/Assets/images/profile/projects-placeholder.svg';
import skillsPlaceholder from 'src/Assets/images/profile/skills-placeholder.svg';
import placeholder from 'src/Assets/images/profile/summary-placeholder.svg';
import Header from 'src/Components/DashboardNew/Header';
import PrimaryImageDialog from 'src/Components/ProfileImageDialog';
import DeleteAccountCard from 'src/Components/v1/DeleteAccountCard';
import ProfileCertificateCard from 'src/Components/v1/ProfileCertificateCard';
import ProfileEducationCard from 'src/Components/v1/ProfileEducationCard';
import ProfileExperienceCard from 'src/Components/v1/ProfileExperienceCard';
import ProfileInterestCard from 'src/Components/v1/ProfileInterestCard';
import ProfileProjectCard from 'src/Components/v1/ProfileProjectCard';
import ProfileSkillsCard from 'src/Components/v1/ProfileSkillsCard';
import ProfileSummaryCard from 'src/Components/v1/ProfileSummaryCard';
import { USER_PROFILE } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';

// import skillsPlaceholder from '../../../Assets/images/profile/skills-placeholder.svg';
import { User } from '../../Api';
import avatar from '../../Assets/images/v1/icons/male-avatar.svg';
import { Auth as AuthConstants } from '../../Constants';

import ProfileInformation from './ProfileInformation';
import ResumeSection from './ResumeSection';

const Profile = () => {
  const [loader, setLoader] = useState(true);

  const [user, setUser] = useState({});
  const [city, setCity] = useState({});
  const [country, setCountry] = useState({});
  const [openProfileImageModal, setOpenProfileImageModal] = useState(false);

  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const context = useContext(AppContext);
  const { getAreaOfInterest } = useContext(HomeContext);

  const getProfile = async () => {
    await getAreaOfInterest();

    User.getUserProfile(token).then((res) => {
      const user = res?.data?.data;

      setUser(user);
      setLoader(false);
    });
  };

  useEffect(() => {
    getProfile();
  }, [openProfileImageModal]);

  useEffect(() => {
    if (user?.city) {
      setCity(user?.city);
    }
    if (user?.country) {
      setCountry(user?.country);
    }
  }, [user]);

  return (
    <div className='lg:ml-80'>
      <div>
        <Header title='Profile' />
        <div className='flex flex-col lg:flex-row'>
          <div className='w-full overflow-hidden px-4 lg:pl-5 '>
            <div className='bg-primaryBg rounded-lg py-5'>
              <div className='flex -mt-5 items-center'>
                <div
                  title={
                    context?.user?.image
                      ? 'Update Profile Image'
                      : 'Add Profile Image '
                  }
                  onClick={() => setOpenProfileImageModal(true)}
                  className={`lg:p-1 mt-7 p-0.5 relative cursor-pointer  ${
                    context?.user?.image
                      ? 'w-fit h-fit'
                      : 'h-20 w-20 sm:w-24 sm:h-24 xl:w-32 xl:h-32 2xl:w-36 2xl:h-36'
                  } bg-white ml-3 md:ml-10 rounded-full `}
                >
                  <img
                    className={`${
                      context?.user?.image
                        ? 'w-24 h-24 xl:w-32 xl:h-32 2xl:w-32 2xl:h-32 m-0 mx-0'
                        : 'h-16 w-16 sm:w-20 sm:h-20 xl:w-24 xl:h-24 2xl:w-28 2xl:h-28 m-1 xl:m-3 2xl:m-3 mx-auto'
                    }  rounded-full`}
                    src={context?.user?.image ? context.user.image : avatar}
                    alt='profile pic'
                  />
                </div>
                <div className='text-white mt-2 ml-1 md:ml-4'>
                  <div className='mx-3'>
                    <h1 className='md:text-2xl font-bold mb-2'>{user?.name}</h1>
                    <div className=' md:text-md text-sm'>
                      {user?.profession?.name}
                    </div>
                    {city?.name ? (
                      <div>
                        {city?.name ?? ''}
                        <span className='mr-1'>,</span>
                        {country?.name ?? ''}
                      </div>
                    ) : null}
                  </div>
                </div>
                <PrimaryImageDialog
                  open={openProfileImageModal}
                  user={user}
                  onClose={() => setOpenProfileImageModal(false)}
                  getProfile={getProfile}
                />
              </div>
            </div>

            <ProfileSummaryCard
              user={user}
              getProfile={getProfile}
              loader={loader}
              placeholderText={USER_PROFILE.NO_SUMMARY}
              placeholderImage={placeholder}
            />
            <ProfileSkillsCard
              user={user}
              getProfile={getProfile}
              loader={loader}
              placeholderText={USER_PROFILE.NO_SKILLS}
              placeholderImage={skillsPlaceholder}
            />
            <ProfileInterestCard
              user={user}
              getProfile={getProfile}
              loader={loader}
              placeholderImage={interestsPlaceholder}
              placeholderText={USER_PROFILE.NO_INTEREST}
            />
            <ProfileExperienceCard
              user={user}
              getProfile={getProfile}
              loader={loader}
              placeholderImage={experiencePlaceholder}
              placeholderText={USER_PROFILE.NO_EXPERIENCE}
            />
            <ProfileEducationCard
              user={user}
              getProfile={getProfile}
              loader={loader}
              placeholderImage={educationPlaceholder}
              placeholderText={USER_PROFILE.NO_EDUCATION}
            />

            <ProfileProjectCard
              user={user}
              getProfile={getProfile}
              loader={loader}
              placeholderText={USER_PROFILE.NO_PROJECT}
              placeholderImage={projectsPlaceholder}
            />
            <ProfileCertificateCard
              user={user}
              getProfile={getProfile}
              loader={loader}
              placeholderImage={certificatesPlaceholder}
              placeholderText={USER_PROFILE.NO_CERTIFICATE}
            />
            <DeleteAccountCard />
          </div>
          <div className='w-full px-4 md:px-2 md:mx-0 lg:w-2/6'>
            <ResumeSection data={user} />
            <div className='mx-2 my-4 w-10/12'>
              <h1 className='text-xl font-medium '>Profile Progress</h1>
              <div className='bg-black lg:w-full w-full my-2 rounded-lg h-3'>
                <div
                  className='h-full text-white text-center rounded-lg bg-orange-400'
                  style={{
                    width: `${
                      // eslint-disable-next-line no-nested-ternary
                      context?.user?.percentage === ''
                        ? 0
                        : context?.user?.percentage >= 87
                        ? 100
                        : context?.user?.percentage
                    }%`,
                  }}
                />
              </div>
              <p className='text-sm text-gray-700'>
                {context?.user?.percentage === ''
                  ? 0
                  : context?.user?.percentage >= 87
                  ? 100
                  : Math.floor(context?.user?.percentage)}
                % Complete
              </p>
            </div>
            <ProfileInformation user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Profile);
