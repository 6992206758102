const CategoryCard = ({ category }) => {
  const { thumbnailUrl, id, description, url } = category;

  return (
    <div
      id={id}
      className='w-full bg-white border border-gray-200 rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105'
    >
      <div className='relative overflow-hidden'>
        <img
          src={thumbnailUrl}
          className='w-full h-[240px] object-scale-down opacity-60 transition-transform duration-500 hover:scale-110'
          alt={description}
        />
        <div className='absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-80'></div>
      </div>
      <div className='mt-2'>
        <a
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          className='block w-full text-center bg-primaryBg text-white p-3 font-medium hover:bg-opacity-90 transition-all duration-200'
        >
          Buy Now
        </a>
      </div>
    </div>
  );
};

export default CategoryCard;
