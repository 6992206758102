/* eslint-disable max-lines */
import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { LiaUserFriendsSolid } from 'react-icons/lia';
import { MdOutlineReviews } from 'react-icons/md';
import { PiBookOpenTextBold } from 'react-icons/pi';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { lowerFirst } from 'lodash';

import JobsLogo from 'src/Assets/images/v1/icons/jobsIconNavbar';
import Drawer from 'src/Components/Drawer';
import { PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';
import { walkthroughSteps } from 'src/Utils/utils';

import TourGuide from '../../../Assets/images/dashboard/tour-guide.svg';
import CLoseLogo from '../../../Assets/images/v1/icons/close-icon.svg';
import DashboardIcon from '../../../Assets/images/v1/icons/dashboardIcon';
import Hamburger from '../../../Assets/images/v1/icons/hamburger.svg';
import LogOutLogo from '../../../Assets/images/v1/icons/log-out-icon.svg';
import Logo from '../../../Assets/images/v1/icons/logo.svg';
import Avatar from '../../../Assets/images/v1/icons/male-avatar.svg';
import PaymentLogo from '../../../Assets/images/v1/icons/paymentIconNavbar';
import ResumeLogo from '../../../Assets/images/v1/icons/resumeIconNavbar';
import SurveyLogo from '../../../Assets/images/v1/icons/serveyIconNavbar';
// import SettingLogo from '../../../Assets/images/v1/icons/settingIconNavbar';
import UpskillLogo from '../../../Assets/images/v1/icons/upskillIconNavbar';
import { Auth as AuthConstants } from '../../../Constants';

const NavbarLinks = ({ logo, link, name, color, children, id, titleClass }) => {
  return (
    <Link
      to={link}
      onClick={() => {
        FirebaseAnalyticsEvent(lowerFirst(name));
      }}
    >
      <div
        className={`flex text-white hover:bg-LightBluePurple p-2 m-0 font-semibold my-2 rounded
        ${color ? 'cursor-pointer' : 'cursor-not-allowed text-black'} 
          `}
        id={id}
      >
        <div className='mr-3 '>{logo}</div>
        <div
          className={`${color ? 'text-white' : ' text-gray-500'} ${titleClass}`}
        >
          {name}
        </div>
        {children}
      </div>
    </Link>
  );
};
const NavbarItems = ({ HandleLogOut, context, isOpen }) => {
  const isEmailVerified = context?.user?.email_verified;
  const homeContext = useContext(HomeContext);
  const { userPayment } = useContext(AppContext);
  const { total, setIsWalkthroughRunning } = homeContext;

  const [login, setLogin] = useState(localStorage.getItem('login'));
  const history = useHistory();

  const isEnable = isEmailVerified && !homeContext?.resumeLoader;
  const enableColor =
    isEmailVerified &&
    userPayment?.payment_status === 'paid' &&
    !homeContext?.resumeLoader;

  // const isWalkthroughSeen = localStorage.getItem('walkthroughSeen');
  const isPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;

  return (
    <div className='fixed h-full overflow-auto mx-8 flex flex-col justify-between'>
      {!isOpen ? (
        <div className='mt-10 mx-2 '>
          <img src={Logo} />
        </div>
      ) : null}

      <div className={`${!isOpen ? 'mt-5' : 'mt-16'}`}>
        <NavbarLinks
          logo={<DashboardIcon isEnable={isEnable} />}
          link={`${isEnable ? '/home/' : '#'} `}
          name={'Dashboard'}
          color={isEnable}
        />
        <NavbarLinks
          logo={<JobsLogo isEnable={enableColor} />}
          link={`${enableColor ? '/home/jobs' : '#'} `}
          name={'Jobs'}
          color={enableColor}
          id='job-section'
        />
        <NavbarLinks
          logo={
            <PiBookOpenTextBold
              size={24}
              className={`${enableColor ? 'opacity-100' : 'opacity-50'} `}
            />
          }
          link={`${enableColor ? '/home/books' : '#'} `}
          name={'Books'}
          color={enableColor}
          id='book-section'
        />

        <NavbarLinks
          logo={<UpskillLogo isEnable={enableColor} />}
          link={`${enableColor ? '/home/upskill' : '#'} `}
          name={'UpSkill'}
          color={enableColor}
          id='upskill-section'
        />

        <NavbarLinks
          logo={<SurveyLogo isEnable={enableColor} />}
          link={`${enableColor ? '/home/survey' : '#'} `}
          name={'Surveys'}
          color={enableColor}
          id='survey-section'
        />
        <NavbarLinks
          logo={<PaymentLogo isEnable={isEnable} />}
          link={`${isEnable ? '/home/payment' : '#'} `}
          name={'Upgrade'}
          color={isEnable}
          id='upgrade-section'
        />
        <div>
          <NavbarLinks
            logo={<ResumeLogo isEnable={isEnable} />}
            link={`${isEnable ? '/home/profile' : '#'} `}
            name={'Profile'}
            color={isEnable}
            id='profile-section'
            titleClass='ml-1'
          >
            <div className='mt-2 bg-gray-100 w-20 rounded-lg h-3 ml-5'>
              <div
                className='h-full text-white text-center rounded-lg bg-orange-400'
                style={{
                  width: `${
                    // eslint-disable-next-line no-nested-ternary
                    context?.user?.percentage === ''
                      ? 0
                      : context?.user?.percentage >= 87
                      ? 100
                      : context?.user?.percentage
                  }%`,
                }}
              ></div>
            </div>
          </NavbarLinks>

          {/* <NavbarLinks
          logo={<SettingLogo isEnable={isEnable} />}
          link={`${isEnable ? '/home/profile' : '#'} `}
          name={'Account Settings'}
          color={isEnable}
        /> */}
        </div>
      </div>

      <div className='flex-1  flex flex-col justify-end mb-5 md:mb-2'>
        {/* <div className={`${!isOpen ? 'h-60' : 'h-20'}`}> */}
        <div>
          {login &&
          userPayment?.payment_status === PAYMENT_STATUS.NOT_PAID &&
          !isOpen &&
          isEmailVerified ? (
            <div className='bg-LightBluePurple mr-5 rounded-lg text-white'>
              <div className='py-4 px-4'>
                <div className='flex justify-between'>
                  <h3 className='my-2'>Free Plan</h3>
                  <img
                    onClick={() => {
                      if (isEnable) {
                        localStorage.removeItem('login');
                        setLogin(false);
                      }
                    }}
                    className={`${
                      isEnable ? 'cursor-pointer' : 'cursor-not-allowed'
                    }`}
                    src={CLoseLogo}
                  />
                </div>
                <p className='my-2 w-48'>
                  Your are on Free Plan. Consider upgrading
                </p>
                <hr className='my-3' />
                <div className='flex'>
                  <h3
                    onClick={() => {
                      if (isEnable) {
                        localStorage.removeItem('login');
                        setLogin(false);
                      }
                    }}
                    className={`${
                      isEnable ? 'cursor-pointer' : 'cursor-not-allowed'
                    }`}
                  >
                    Dismiss
                  </h3>
                  <h3 className='mx-3 '>
                    <Link
                      className={` ${
                        isEnable ? 'cursor-pointer' : 'cursor-not-allowed'
                      }`}
                      to={`${isEnable ? '/home/payment' : '#'}`}
                    >
                      Upgrade Plan
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {Number(total) ? (
          <div
            className='mx-2 flex items-center cursor-pointer my-2 lg:mb-0'
            onClick={() => {
              FirebaseAnalyticsEvent('take_tour');
              // eslint-disable-next-line no-undef
              walkthroughSteps(isPremium, setIsWalkthroughRunning).drive();
              setIsWalkthroughRunning(true);
            }}
          >
            <img src={TourGuide} />
            {/* <MdTour
              color='white'
              className={` ${isEnable ? 'opacity-100' : 'opacity-60'}`}
              size={20}
            /> */}
            <p className='text-white ml-2 font-semibold text-sm'>Take a Tour</p>
          </div>
        ) : null}
        {isEmailVerified ? (
          <>
            <div
              className={'mx-2 flex items-center cursor-pointer my-2 lg:mb-0'}
            >
              <LiaUserFriendsSolid
                color='white'
                className={` ${isEnable ? 'opacity-100' : 'opacity-60'}`}
                size={20}
              />
              <p
                className={`text-white ml-2 text-sm font-semibold ${
                  isEnable ? 'opacity-100' : 'opacity-40'
                }`}
                onClick={() =>
                  isEnable ? history.push('/home/refer-a-friend') : '#'
                }
              >
                Refer a Friend
              </p>
            </div>
            <div
              className={'mx-2 flex items-center cursor-pointer my-2 lg:mb-0'}
            >
              <MdOutlineReviews
                color='white'
                className={` ${isEnable ? 'opacity-100' : 'opacity-60'}`}
                size={20}
              />
              <p
                className={`text-white ml-2 text-sm font-semibold ${
                  isEnable ? 'opacity-100' : 'opacity-40'
                }`}
                onClick={() => (isEnable ? history.push('/home/reviews') : '#')}
              >
                Submit Review
              </p>
            </div>
          </>
        ) : null}

        {!isOpen ? (
          <hr className={`my-5 ${isOpen ? '-ml-5 w-full' : 'w-64'}  `} />
        ) : null}
        <div className='flex lg:justify-between lg:w-72 mb-5 '>
          <div className='flex gap-0.5'>
            <div className='h-8 w-8 mr-2 mt-1 bg-gray-300 rounded-full flex justify-center items-center'>
              <img
                className='mx-2 h-8 w-8  rounded-full'
                src={context.user.image ? context.user.image : Avatar}
              />
            </div>
            <h3 className='text-white text-xl mt-1 font-semibold'>
              {context?.user?.name}
            </h3>
          </div>
          <img
            className='cursor-pointer mx-5 mt-2'
            src={LogOutLogo}
            onClick={HandleLogOut}
            alt='log-out'
          />
        </div>
      </div>
    </div>
  );
};

const Navbar = () => {
  const context = useContext(AppContext);
  const [, , removeCookie] = useCookies([AuthConstants.JWT_TOKEN]);
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const HandleLogOut = () => {
    removeCookie(AuthConstants.JWT_TOKEN, {
      path: '/',
    });
    localStorage.removeItem('loginData');
    localStorage.removeItem('login');
    localStorage.removeItem('isBanner');
    removeCookie(AuthConstants.PROFILE_STATUS, {
      path: '/',
    });
    history.push('/sign-in');
  };

  return (
    <div className={'bg-primaryBg pb-10 lg:pb-0 lg:w-80 lg:fixed h-full z-10'}>
      <div className='lg:block hidden'>
        <NavbarItems
          isOpen={isOpen}
          HandleLogOut={HandleLogOut}
          context={context}
        />
      </div>
      <div className='mt-10 mx-5 lg:hidden'>
        <img src={Logo} />
      </div>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className='flex justify-between mx-5 mt-10'>
          <div className='mx-5'>
            <img src={Logo} />
          </div>
          <div>
            <button
              className=' text-white cursor-pointer px-4 py-1'
              onClick={() => setIsOpen(!isOpen)}
            >
              <img src={Hamburger} />
            </button>
          </div>
        </div>
        <NavbarItems
          isOpen={isOpen}
          HandleLogOut={HandleLogOut}
          context={context}
        />
      </Drawer>
      <div className='lg:hidden flex justify-end -mt-8 ml-10'>
        <button
          className=' text-white rounded cursor-pointer px-4 py-1'
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={Hamburger} />
        </button>
      </div>
    </div>
  );
};
export default Navbar;
