import Api from './index';
import Response from './Response';

const { next } = Response;

const Books = {
  getAllBooks: async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await Api.get('/scraper/books', config);
      return response?.data?.data || [];
    } catch (e) {
      next(e.response);
    }
  },

  getUserScore: async (token) => {
    const endpoint = '/user';
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await Api.get(endpoint, config);
      return response?.data?.data?.total
        ? parseFloat(response.data.data.total)
        : null;
    } catch (e) {
      next(e.response);
    }
  },
};

export default Books;
