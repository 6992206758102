/* eslint-disable simple-import-sort/imports */

import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Snackbar } from '@material-ui/core';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Blogs from './Containers/Blogs';
import Blog from './Containers/Blogs/blog';
import BecomeAPartner from './Containers/BecomePartner';
import Careers from './Containers/Careers';
import Cookies from './Containers/Cookies';
import ForgetPassword from './Containers/ForgetPassword';
import Home from './Containers/Home';
import HowItWorks from './Containers/HowItsWorks/HowItWorks';
import LandingPage from './Containers/LandingPage';
import Login from './Containers/Login';
// import OurTeam from './Containers/OurTeam';
import Pricing from './Containers/Pricing';
import PrivacyPolicy from './Containers/PrivacyPolicy';
import ProfileCompletion from './Containers/ProfileCompletion';
import ResetPassword from './Containers/ResetPassword';
import SignUp from './Containers/SignUp';
import TermsAndCondition from './Containers/TermsAndCondition';
import VerifyEmail from './Containers/VerifyEmail';
import Routes from './Utils/Routes';
import { Auth as AuthConstants } from './Constants';
import { AppContext, ProfileCompletionProvider } from './Contexts';
import './App.css';
// import Chatbot from './Components/Chatbot/Chatbot';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  return (
    <Route
      {...rest}
      render={(props) =>
        cookies[AuthConstants.JWT_TOKEN] ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <Redirect to={Routes.Login} />
        )
      }
    />
  );
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);

  return (
    <Route
      {...rest}
      render={(props) =>
        !cookies[AuthConstants.JWT_TOKEN] ||
        cookies[AuthConstants.JWT_TOKEN].length === 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to='/home' />
        )
      }
    />
  );
};

const App = () => {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
    >
      <ProfileCompletionProvider>
        <BrowserRouter>
          <>
            <Switch>
              <Route component={LandingPage} exact path={Routes.Landing} />
              <Route
                component={TermsAndCondition}
                exact
                path={Routes.TermsAndCondition}
              />
              {/* <Route component={OurTeam} path={Routes.OurTeam} /> */}
              <Route
                component={PrivacyPolicy}
                exact
                path={Routes.PrivacyPolicy}
              />
              <Route component={Cookies} exact path={Routes.CookiesPolicy} />
              <PrivateRoute component={Home} exact path={Routes.Home} />
              <AuthRoute
                component={ForgetPassword}
                exact
                path={Routes.ForgetPassword}
              />
              <AuthRoute component={Login} exact path={Routes.Login} />
              <Route component={Pricing} exact path={Routes.Pricing} />
              <Route component={Blogs} exact path={Routes.Blogs} />
              <Route component={Blog} exact path={`${Routes.Blogs}/:slug`} />
              <Route
                component={BecomeAPartner}
                exact
                path={Routes.BecomeAPartner}
              />
              <Route component={HowItWorks} exact path={Routes.HowItsWork} />
              <Route component={Careers} exact path={Routes.Career} />
              <AuthRoute component={SignUp} exact path={Routes.SignUp} />
              <AuthRoute
                component={ResetPassword}
                exact
                path={Routes.ResetPassword}
              />
              <Route component={VerifyEmail} exact path={Routes.VerifyEmail} />
              <AuthRoute
                component={ProfileCompletion}
                exact
                path={Routes.ProfileCompletion}
              />
              <AuthRoute exact path='/linkedin' component={LinkedInCallback} />
              <Redirect to='/home' />
            </Switch>
          </>
        </BrowserRouter>
        <ShowError />
        {/* <Chatbot /> */}
      </ProfileCompletionProvider>
    </GoogleOAuthProvider>
  );
};

const ShowError = () => {
  const context = useContext(AppContext);
  const [, setError] = useState(false);

  useEffect(() => {
    if (context.message && context.message.length > 0) {
      setError(true);
    }
  }, [context]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={context.toggle}
      onClose={() => context.setToggle(false)}
      message={context.message}
    />
  );
};

export default App;
