import React, { useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

import { AppContext } from 'src/Contexts';
import { mapProjects } from 'src/Utils/utils';

import { User } from '../../Api';
import addIcon from '../../Assets/images/v1/icons/add-icon.svg';
import { Auth as AuthConstants } from '../../Constants';
import DeletionDialog from '../DeletionDialog';
import Loader from '../Loader';
import ProjectInputDialog from '../ProjectInputDialog';

import DesignationCard from './DesignationCard';
import ProfileEmptyCard from './ProfileEmptyCard';

const ProfileProjectCard = ({
  user,
  getProfile,
  loader,
  placeholderText,
  placeholderImage,
}) => {
  const { state } = useLocation();
  const [open, setOpen] = useState(state?.projects);
  const [selectedItem, setSelectedItem] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deletingLoader, setDeletingLoader] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const context = useContext(AppContext);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const updateUser = () => context.updateUser(token);

  const projects = useMemo(
    () => (Array.isArray(user?.projects) ? mapProjects(user?.projects) : []),
    [user?.projects],
  );

  const refreshProjects = () => {
    updateUser();
    getProfile();
  };

  const handleEdit = (data) => {
    setSelectedItem(data);
    setOpen(true);
  };

  const handleProjectUpdate = async (data) => {
    try {
      const res = await User.updateProject(data, token, { id: data.id });
      refreshProjects(res?.data?.data);
    } catch (e) {
      console.error('Failed:', e);
    }
  };
  const handleDeleteProject = (data) => {
    setDeletingLoader(true);
    User.deleteProject(data.id, token)
      .then((res) => {
        refreshProjects(res?.data?.data);
        setDeletingLoader(false);
        setDeletingIndex(null);
      })
      .catch((err) => {
        setDeletingLoader(false);
        setDeletingIndex(null);
        console.error('Delete Project: ', err);
      });
  };

  const renderProjects = () => {
    if (projects?.length) {
      return projects?.map((data, index) => {
        return (
          <DesignationCard
            key={index}
            title={data.title}
            image={data.image}
            startYear={data.startYear}
            endYear={data.endYear}
            loader={deletingLoader}
            isDeleting={deletingIndex === index}
            onEdit={() => handleEdit(data)}
            onDelete={() => {
              setConfirmModalOpen(true);
              setSelectedItem(data);
              setDeletingIndex(index);
            }}
          />
        );
      });
    }
    return <ProfileEmptyCard {...{ placeholderImage, placeholderText }} />;
  };

  return (
    <div id='projects' className='bg-white my-10 py-7 px-5'>
      <div className='flex justify-between mb-6'>
        <h1 className='text-2xl font-semibold'>Projects</h1>
        <img
          className='cursor-pointer'
          onClick={() => {
            setOpen(true);
            setSelectedItem(null);
          }}
          src={addIcon}
        />
      </div>

      {loader ? (
        <div className='flex justify-center'>
          <Loader />
          <h1 className='mt-1'>Getting your Projects</h1>
        </div>
      ) : (
        renderProjects()
      )}
      {confirmModalOpen ? (
        <DeletionDialog
          title='Delete'
          description='Are you sure you want to delete this item?'
          open={confirmModalOpen}
          onClose={() => {
            setSelectedItem(null);
            setConfirmModalOpen(false);
          }}
          onPositiveAction={() => handleDeleteProject(selectedItem)}
        />
      ) : null}

      {open ? (
        <ProjectInputDialog
          {...selectedItem}
          isCurrent={!selectedItem?.endYear && selectedItem?.startYear}
          onSubmitted={(data) => {
            setSelectedItem(null);
            handleProjectUpdate(data);
          }}
          open={open}
          onClose={() => {
            setSelectedItem(null);
            setOpen(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default ProfileProjectCard;
