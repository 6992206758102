import React, { useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

import { AppContext } from 'src/Contexts';
import { mapExperience } from 'src/Utils/utils';

import { User } from '../../Api';
import addIcon from '../../Assets/images/v1/icons/add-icon.svg';
import { Auth as AuthConstants } from '../../Constants';
import DeletionDialog from '../DeletionDialog';
import ExperienceInputDialog from '../ExperienceInputDialog';
import Loader from '../Loader';

import DesignationCard from './DesignationCard';
import ProfileEmptyCard from './ProfileEmptyCard';

const ProfileExperienceCard = ({
  user,
  getProfile,
  loader,
  placeholderText,
  placeholderImage,
}) => {
  const { state } = useLocation();

  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(state?.experience);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deletingLoader, setDeletingLoader] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState(null);

  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const context = useContext(AppContext);
  const updateUser = () => context.updateUser(token);

  const experiences = useMemo(
    () =>
      Array.isArray(user?.experience) ? mapExperience(user.experience) : [],
    [user?.experience],
  );

  const refreshExperience = () => {
    updateUser();
    getProfile();
  };

  const handleEdit = (data) => {
    setSelectedItem(data);
    setOpen(true);
  };

  const handleExperienceUpdate = async (data) => {
    try {
      const res = await User.updateExperience(data, token, { id: data.id });
      refreshExperience(res?.data?.data);
    } catch (e) {
      console.error('Failed:', e);
    }
  };
  const handleDeleteExperience = (data) => {
    setDeletingLoader(true);

    User.deleteExperience(data.id, token)
      .then((res) => {
        refreshExperience(res?.data?.data);
        setDeletingLoader(false);
        setDeletingIndex(null);
      })
      .catch((err) => {
        setDeletingLoader(false);
        setDeletingIndex(null);

        console.error('Delete Experience: ', err);
      });
  };

  const renderExperience = () => {
    if (experiences?.length) {
      return experiences?.map((data, index) => {
        return (
          <DesignationCard
            key={index}
            title={data.title}
            description={`${data.company}, ${data.location || ''}`}
            startYear={data.startYear}
            startMonth={data?.start_month}
            endMonth={data?.end_month}
            endYear={data.endYear}
            loader={deletingLoader}
            isDeleting={deletingIndex === index}
            onEdit={() => handleEdit(data, index)}
            onDelete={() => {
              setConfirmModalOpen(true);
              setSelectedItem(data);
              setDeletingIndex(index);
            }}
          />
        );
      });
    }
    return <ProfileEmptyCard {...{ placeholderImage, placeholderText }} />;
  };

  return (
    <div id='experience' className='bg-white my-10 py-7 px-5'>
      <div className='flex justify-between mb-6'>
        <h1 className='text-2xl font-semibold'>Experience</h1>
        <img
          className='cursor-pointer'
          onClick={() => {
            setOpen(true);
            setSelectedItem(null);
          }}
          src={addIcon}
          alt=''
        />
      </div>
      {loader ? (
        <div className='flex justify-center'>
          <Loader />
          <h1 className='mt-1'>Getting your Experience</h1>
        </div>
      ) : (
        renderExperience()
      )}

      {confirmModalOpen ? (
        <DeletionDialog
          title='Delete'
          description='Are you sure you want to delete this item?'
          open={confirmModalOpen}
          onClose={() => {
            setSelectedItem(null);
            setConfirmModalOpen(false);
          }}
          onPositiveAction={() => handleDeleteExperience(selectedItem)}
        />
      ) : null}
      {open ? (
        <ExperienceInputDialog
          {...selectedItem}
          isCurrent={!selectedItem?.endYear && selectedItem?.startYear}
          open={open}
          onClose={() => {
            setSelectedItem(null);
            setOpen(false);
          }}
          onSubmitted={(data) => {
            handleExperienceUpdate(data);
            setOpen(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default ProfileExperienceCard;
