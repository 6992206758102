import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import GetPaymentHistory from 'src/Api/GetUserPayment';
import { PAYMENT_STATUS } from 'src/Constants/Common';

import { User } from '../Api';
import { Auth as AuthConstants } from '../Constants';

const AppContext = React.createContext({});

const AppContextProvider = (props) => {
  const [user, setUser] = useState({
    percentage: 0,
    name: '',
    email: '',
    image: '',
    resume: false,
    email_verified: 1,
  });
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const [message, setMessage] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [userPayment, setUserPayment] = useState(null);

  const showError = (message) => {
    setMessage(message);
    setToggle(!toggle);
  };

  const updateUser = (token) => {
    User.getProfileStatus(token).then((res) => {
      setUser({ ...res, percentage: Number(res.percentage) });
    });
  };

  const fetchUserPayment = async (loginToken) => {
    const isUserLogin = localStorage.getItem('loginData');

    if (isUserLogin) {
      await GetPaymentHistory.UserPayment(loginToken ? loginToken : token)
        .then((data) => {
          if (Object.keys(data?.data?.data)?.length) {
            return setUserPayment(data.data.data);
          }

          setUserPayment({
            payment_id: '',
            payment_status: PAYMENT_STATUS.NOT_PAID,
          });
        })
        .catch((e) => console.error(e))
        .finally(() => {});
    }
  };

  useEffect(() => {
    fetchUserPayment();
  }, []);

  return (
    <AppContext.Provider
      value={{
        message,
        showError,
        setToggle,
        toggle,
        updateUser,
        user,
        fetchUserPayment,
        userPayment,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
