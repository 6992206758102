import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import { customSort } from 'src/Utils/utils';

import { Skills } from '../../Api';
import { Auth as AuthConstants } from '../../Constants';
import SearchDropdownNew from '../SearchDropDownNew';
const SkillsDropdown = (props) => {
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);

  const SIZE = 50;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${cookies[AuthConstants.JWT_TOKEN]}`,
    },
  };

  const [skills, setSkills] = useState([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  const listSkills = ({ clear = false, page = 0 } = {}) => {
    Skills.list(props.profession_id, requestConfig, {
      beg: page,
      size: SIZE,
      query: query,
      type: props.type,
    })
      .then((res) => {
        if (res?.data?.data?.skills?.length === 0) return;
        const sortedArray = customSort(res?.data?.data?.skills, query);

        setSkills([...(clear ? [] : skills), ...sortedArray]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listSkills({ clear: true });
  }, [query]);

  useEffect(() => {
    listSkills({
      page: page,
    });
  }, [page]);

  return (
    <SearchDropdownNew
      value={props.value}
      onChange={(item) => {
        setPage(0);
        setQuery('');
        props.onChange(item);
      }}
      labelAttribute='name'
      matchAttribute='id'
      data={skills}
      width={'auto'}
      loading={loading}
      inputPlaceholder={
        props.type === 'h' ? 'Add Technical Skills' : 'Add Soft Skills'
      }
      requestMoreData={() => {
        setPage(page + 1);
      }}
      onSearch={(input) => {
        if (input !== query) {
          setPage(0);
          setQuery(input);
        }
      }}
    />
  );
};

SkillsDropdown.propTypes = {
  type: PropTypes.oneOf(['h', 's']),
  profession_id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

SkillsDropdown.defaultProps = {
  type: 'h',
};

export default SkillsDropdown;
