import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
// import { FaBook } from 'react-icons/fa';
import { useHistory, withRouter } from 'react-router-dom';

import { User } from 'src/Api';
import Books from 'src/Api/Books';
import CategoryCard from 'src/Components/Books/CategoryCard';
import { Auth as AuthConstants } from 'src/Constants';
import { PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext } from 'src/Contexts';

const BooksComponent = () => {
  document.title = 'Home | Books';
  const [books, setBooks] = useState([]);
  const [userScore, setUserScore] = useState(null);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const { user, userPayment } = useContext(AppContext);

  const verifiedEmail = user?.email_verified;
  const history = useHistory();
  const paymentHistory = userPayment?.payment_status;

  useEffect(() => {
    if (!verifiedEmail || paymentHistory === PAYMENT_STATUS.NOT_PAID) {
      return history.push('/home');
    }
  }, [verifiedEmail, paymentHistory]);

  useEffect(() => {
    const fetchBooksAndScore = async () => {
      try {
        const fetchedBooks = await Books.getAllBooks(token);
        setBooks(fetchedBooks);

        if (token) {
          const score = await User.getScores(token);
          setUserScore(score.total);
        }
      } catch (error) {
        console.error('Error while fetching data:', error);
      }
    };

    fetchBooksAndScore();
  }, [token]);

  useEffect(() => {
    if (userScore !== null) {
      const lowerBound = Math.floor(userScore / 10) * 10 + 1;
      const upperBound = lowerBound + 9;

      const filtered = books.filter((book) => {
        if (!book.range) {
          return false;
        }

        const range = book.range.split('-').map(Number);
        return range[0] >= lowerBound && range[1] <= upperBound;
      });

      setFilteredBooks(filtered);
    }
  }, [userScore, books]);

  return (
    <div className='lg:ml-80  mt-5 overflow-hidden bg-white lg:mt-0 isolate sm:pt-10'>
      <div className='px-6 lg:px-10 mx-auto '>
        <div className=' mx-auto lg:mx-0 '>
          <h2 className='text-xl font-bold tracking-tight text-black sm:text-3xl'>
            Books To Read
          </h2>
          <h2 className='text-xl mt-8 font-semibold text-SSBlack'>
            Recommended books based on your score
          </h2>
        </div>

        <div className='max-w-2xl mx-auto lg:mx-0 lg:max-w-none'>
          {/* class="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-3 mt-5" */}
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-8 sm:mt-10 lg:grid-cols-3  2xl:grid-cols-4 3xl:grid-cols-5'>
            {filteredBooks?.length
              ? filteredBooks.map((book) => (
                  <CategoryCard key={book.id} category={book} />
                ))
              : null}
          </div>
        </div>
        {!filteredBooks.length && (
          <div>
            <p className='mt-2 text-center font-[Karla-Regular] font-semibold text-xl'>
              No Book Found
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(BooksComponent);
